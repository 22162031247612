<template>
	<div class="person_infor">
		<div class="login_top">
			<div class="img"> <img src="@/assets/login/flag.png"></div>
			<div class="login_title" @click="backClick">{{$t('other.myMessage')}}</div>
			<div class="front_img">
				<img src="../../../assets/home_icons/front.png">
			</div>
			<div class="sub_title">{{$t('title.systemMessages')}}</div>
		</div>
		<div class="all_message">
			<div class="message_item" v-for="(item,index) in messageList" :key="index">
				<div class="message_item_top">
					<div class="message_item_left">
						<img :src="item.thumb">
					</div>
					<div class="message_item_right">
						<div class="message_name">{{item.title}}</div>
						<div class="message_time">{{item.create_time}}</div>
					</div>
				</div>
				<div class="message_item_bottom">
					<span v-html="item.contents"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				messageList: [],
				pageNo: 1,
				pageSize: 10000,
			}
		},
		mounted() {
			this.getMsgType();
		},
		methods: {
			// 获取分类信息
			getMsgType() {
				let data = {
					page: this.pageNo,
					pageSize: this.pageSize,
					msgtype: 3,
				}

				this.$http.getMsgList(data).then(res => {
					if (res.code == 1) {
						this.messageList = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})

			},
			backClick() {
				this.$router.back();
			},
		},
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 100%;
	}


	.login_top {
		display: flex;
		align-items: center;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;


		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;

			&:hover {
				cursor: pointer;
			}
		}

		.front_img {
			display: flex;
			align-items: center;
			margin: 0 12px;

			img {
				width: 8px;
				height: 14px;
			}
		}
	}

	.all_message {
		padding: 0 24px;
		box-sizing: border-box;

		.message_item {
			padding-top: 32px;

			&:hover {
				cursor: pointer;
			}

			.message_item_top {
				display: flex;

				.message_item_left {
					img {
						width: 44px;
						height: 44px;
						border-radius: 50%;
					}
				}

				.message_item_right {
					margin-left: 24px;

					.message_name {
						text-align: left;
						font-size: 16px;
						color: #333333;
					}

					.message_time {
						margin: 8px 0 16px;
						color: #BFBFBF;
						font-size: 14px;
					}
				}
			}

			.message_item_bottom {
				padding-bottom: 24px;
				border-bottom: 1px solid #EEEEEE;
				margin-left: 68px;
				color: #666666;
				line-height: 24px;
				font-size: 14px;
				text-align: left;
				word-break: break-all;
			}
		}
	}

	.title_all {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: bold;

		.title_img {
			margin-right: 8px;
			word-break: break-all;

			img {
				display: flex;
				align-items: center;
				width: 24px;
				height: 24px;
			}
		}

		.content {
			font-size: 14px;


		}
	}
</style>
